.customer-card {
  width: 302px;
  height: 274px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
  padding: 11px 10px 19px 11px;
  border-radius: 20px 20px 10px 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05),
    0px 4px 4px 0px rgba(0, 0, 0, 0.03) inset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 5em;
  flex-shrink: 0;
}

.coach-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  top: -65px;
}

.coach-view-btn {
  color: #66a3a6;
  width: 100px;
  background-color: #fff;
  height: 32px;
  border-radius: 5px;
  border: 1px solid #66a3a6;
  margin-top: 1em;
  cursor: pointer;
}

.coach-card p {
  color: #000;
  font-family: Nunito;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  line-height: normal;
  text-transform: capitalize;
}

.coach-details span {
  text-align: center;
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  word-break: break-all;
}

.coach-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 6em; */
  height: 57%;
}

.coach-details span {
  width: 16vw;
  height: 3.5vw;
  /* ov erflow: auto; */
}

.trmr.coach {
  width: 27%;
  height: 253px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
  padding: 11px 10px 19px 11px;
  position: relative;
  background-color: #ffffff;
}

.coach {
  width: 19%;
  height: 250px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
  padding: 11px 10px 19px 11px;
  position: relative;
  background-color: #ffffff;
}

.coach-rating {
  /* position: absolute; */
  top: 46%;
  left: 6%;
}

.img-rating {
  display: flex;
  align-items: center;
  gap: 3%;
}

.coachName {
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin: 0;
  max-height: 2rem;
  overflow: auto;
  display: block;
  width: 100%;
  text-align: center;
}

/* .room-img.coach-card {
  object-fit: contain;
  background-color: white;
} */

.coach-card.room-img {
  width: 42%;
  height: unset;
  border-radius: 20px 20px 10px 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  /* object-fit: contain; */
  background-color: white;
  box-sizing: border-box;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.03 */
}

/* HOVER EFFECT  */
.extraClass {
  transition: 0.7s;
  position: relative; /* Ensure relative positioning for absolute children */
}

.coach.extraClass:before {
  content: "";
  position: absolute;
  background: rgb(85 108 214 / 5%);
  /* background-color: red; */
  width: 200px;
  height: 200px;
  z-index: -1;
  transform: rotate(42deg);
  left: 50px;
  /* top: 50px; */
  border-radius: 35px;
}

.extraClass:hover {
  background: #99c9cb;
  color: #fff;
  transform: scale(1.1);
  z-index: 9;
}

.extraClass:hover span {
  color: #fff;
}

.hovercolorbubble {
  display: block;
  position: absolute;
  background: rgb(54 81 207 / 15%);
  width: 100rem;
  height: 100rem;
  left: 0;
  right: 0;
  z-index: -1;
  top: 16rem;
  border-radius: 50%;
  transform: rotate(-36deg);
  left: 0rem;
  transition: 0.7s;
}

.arrow-container {
  display: inline-block;
  transition: 0.3s; /* Smooth transition for hover effect */
}

.arrow-container svg .circle-bg {
  fill: #66a3a6; /* Initial background color */
}

.arrow-container svg .arrow {
  fill: white; /* Initial arrow color */
}

/* Apply hover effect to arrow-container when the parent .extraClass is hovered */
.extraClass:hover .arrow-container svg .circle-bg {
  fill: white; /* Background color on hover */
}

.extraClass:hover .arrow-container svg .arrow {
  fill: #66a3a6; /* Arrow color on hover */
}

/*  */
@media screen and (max-width: 2560px) {
  .trmr.coach{
    width: 30%;
  }
}
@media screen and (max-width: 1920px) {
  .trmr.coach{
    width: 27%;
  }

}
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .coach-card {
    width: 16vw;
    height: 15vw;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
      0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
    padding: 11px 10px 19px 11px;
    border-radius: 20px 20px 10px 10px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05),
      0px 4px 4px 0px rgba(0, 0, 0, 0.03) inset;
  }

  .coach-details {
    height: 69%;
  }

  .coach-card p {
    font-size: 1.4vw;
  }

  .coach-img {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    position: absolute;
    /* top: -31%; */
  }



  .coach-details span {
    max-height: 4vw;
    overflow: auto;
    height: 9vw;
    overflow: scroll;
    color: #000;
    text-align: center;
    font-family: Nunito;
    font-size: 0.9vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
  }

  .coach-view-btn {
    width: 6vw;
    color: #66a3a6;
    background-color: #fff;
    height: 2vw;
    border-radius: 5px;
    border: 1px solid #66a3a6;
    margin-top: 1em;
    cursor: pointer;
  }

  .coach-rating > .star-rating svg {
    width: 1vw;
  }

  .trmr.coach {
    width: 30%;
    height: 230px;
  }
}


@media only screen and (max-width: 1440px) {
  .coach-card.room-img {
    padding: 0;
  }  
}

@media only screen and (min-width: 769px) and (max-width: 1023px) {
  .coach-card {
    width: 34vw;
    height: 28vw;
  }
  .coach-img {
    width: 16vw;
    height: 16vw;
    border-radius: 50%;
    position: absolute;
    top: -65px;
  }

  .coach.coachCard-tablet {
    position: relative;
    height: 30vw;
    width: 35vw;
  }

  .coach-rating {
    position: absolute;
    top: 30%;
  }

  .coach-details > p {
    color: #000;
    font-family: Nunito;
    font-size: 2.5vw;
    font-style: normal;
    font-weight: 400;
    margin-top: 0;
    line-height: normal;
    text-transform: capitalize;
  }

  .coach-details > span {
    color: #000;
    text-align: center;
    font-family: Nunito;
    font-size: 1.6vw;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
  }
}

@media only screen and (min-width: 328px) and (max-width: 768px) {
  .coach-card p {
    color: #000;
    font-family: Nunito;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-top: auto;
    margin-bottom: 0px;
  }
  .coach {
    width: 81vw;
    height: 27vh;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
      0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
  }

  .coachCard-Mobile {
    width: 81vw;
    height: 27vh;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
      0px 4px 4px 0px rgba(0, 0, 0, 0.05) inset;
    padding-bottom: 5vw;
  }

  .coach-rating {
    position: absolute;
    top: 48% !important;
    left: 6%;
  }

  .coach-rating > .star-rating svg {
    font-size: 4.2vw;
  }
}

@media only screen and (min-width: 720px) and (max-width: 1280px) {
}

@media only screen and (max-width: 900px) {
  .trmr.coach {
    position: relative;
    height: 30vw;
    width: 35vw;
  }
}

@media only screen and (max-width: 400) {
  .coach-card p {
    margin-bottom: 0px !important;
  }
}
